import { useState, useEffect } from 'react';
import api from '../../services/api';

const useTaskService = () => {
  const [tasks, setTasks] = useState([]);

  const fetchTasks = async () => {
    try {
      const response = await api.get("/tasks");
      setTasks(response.data);
      return response.data;  // Certifique-se de retornar os dados aqui
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const createTask = async (description, userId, companyId) => {
    try {
      const newTask = {
        description,
        userId,
        completed: false,
        companyId // Inclua o companyId
      };
      await api.post("/tasks", newTask);
      fetchTasks();
    } catch (error) {
      console.error("Error creating task:", error);
    }
  };

  const updateTask = async (taskId, updatedFields) => {
    try {
      await api.put(`/tasks/${taskId}`, updatedFields);
      fetchTasks();
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };

  const deleteTask = async (taskId) => {
    try {
      await api.delete(`/tasks/${taskId}`);
      fetchTasks();
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  return {
    tasks,
    fetchTasks,
    createTask,
    updateTask,
    deleteTask
  };
};

export default useTaskService;
