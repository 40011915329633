import React, { useState, useEffect, useContext } from 'react'; // Adicionado useContext
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Checkbox from '@material-ui/core/Checkbox';
import useTaskService from "../../hooks/useTaskService";
import { AuthContext } from "../../context/Auth/AuthContext"; // Ajuste o caminho conforme necessário

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '2rem'
  },
  inputContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: '1rem'
  },
  input: {
    flexGrow: 1,
    marginRight: '1rem'
  },
  table: {
    minWidth: 650,
  },
  completed: {
    backgroundColor: '#e0e0e0',
  },
  notCompleted: {
    backgroundColor: '#fff',
  },
  tableRow: {
    height: '10px', // Ajuste a altura das linhas aqui
  }
});

const ToDoList = () => {
  const classes = useStyles();
  const { tasks, fetchTasks, createTask, updateTask, deleteTask } = useTaskService();
  const { user } = useContext(AuthContext); // Obtém o usuário do contexto de autenticação

  const [task, setTask] = useState('');
  const [editIndex, setEditIndex] = useState(-1);

  useEffect(() => {
    fetchTasks();
  }, []);

  const handleTaskChange = (event) => {
    setTask(event.target.value);
  };

  const handleAddTask = async () => {
    if (!task.trim()) {
      return;
    }

    if (editIndex >= 0) {
      const taskToUpdate = tasks[editIndex];
      await updateTask(taskToUpdate.id, { description: task });
      setEditIndex(-1);
    } else {
      await createTask(task, user.id); // Usando o ID do usuário do contexto de autenticação
    }
    setTask('');
  };

  const handleEditTask = (index) => {
    setTask(tasks[index].description);
    setEditIndex(index);
  };

  const handleDeleteTask = async (index) => {
    const taskToDelete = tasks[index];
    await deleteTask(taskToDelete.id);
  };

  const handleToggleCompleted = async (index) => {
    const taskToUpdate = tasks[index];
    await updateTask(taskToUpdate.id, { description: taskToUpdate.description, completed: !taskToUpdate.completed });
  };

  return (
    <div className={classes.root}>
      <div className={classes.inputContainer}>
        <TextField
          className={classes.input}
          label="Nova tarefa"
          value={task}
          onChange={handleTaskChange}
          variant="outlined"
        />
        <Button variant="contained" color="primary" onClick={handleAddTask}>
          {editIndex >= 0 ? 'Salvar' : 'Adicionar'}
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="tabela de tarefas">
          <TableHead>
            <TableRow>
              <TableCell>Concluído</TableCell>
              <TableCell>Tarefa</TableCell>
              <TableCell>Criado Por</TableCell>
              <TableCell>Atualização</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((task, index) => (
              <TableRow key={task.id} className={task.completed ? classes.completed : classes.notCompleted} classes={{ root: classes.tableRow }}>
                <TableCell>
                  <Checkbox
                    checked={task.completed}
                    onChange={() => handleToggleCompleted(index)}
                    color="primary"
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {task.description}
                </TableCell>
                <TableCell>{task.user ? task.user.name : ''}</TableCell>
                <TableCell>{new Date(task.updatedAt).toLocaleString()}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleEditTask(index)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteTask(index)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ToDoList;
