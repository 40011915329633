import React, { useState } from "react";
import ContactModal from "../ContactModal";
import Button from "@material-ui/core/Button";
import "./VcardPreview.css";

const VcardPreview = ({ contact, numbers }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const formatPhoneNumber = (number) => {
    return number.replace(/[^\d]/g, ""); // Remove tudo que não é dígito
  };

  const formattedNumber = formatPhoneNumber(numbers);

  return (
    <div className="vcard-preview">
      <p><strong>Nome:</strong> {contact}</p>
      <p><strong>Telefone:</strong> {formattedNumber}</p>
      <Button onClick={handleOpenModal} color="primary" variant="contained">
        Salvar Contato
      </Button>

      <ContactModal
        open={openModal}
        onClose={handleCloseModal}
        initialValues={{ name: contact, number: formattedNumber }}
        onSave={() => setOpenModal(false)}
      />
    </div>
  );
};

export default VcardPreview;